<template>
  <div class="partSet">
    <a-form>
      <!-- <a-form-item>
        <a-select placeholder="请选择所属部件" v-model="value" @change="handleSelectChange">
          <a-select-option v-for="item in partList" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item> -->
      <!-- <a-form-item>
        <a-button type="danger" @click="deleteNode"> 删除节点 </a-button>
      </a-form-item> -->
    </a-form>
    <!--使用draggable组件-->
    <div class="itxst">
      <div class="col">
        <div class="title">待分组部件</div>
        <draggable
          v-model="arr1"
          group="site"
          animation="300"
          dragClass="dragClass"
          ghostClass="ghostClass"
          chosenClass="chosenClass"
          @start="onStart"
          @end="onEnd"
          @move="onMove"
        >
          <transition-group>
            <div
              class="item2"
              :class="item.active ? 'partSelected' : ''"
              v-for="item in arr1"
              :key="item.id"
              @click="partClick(item)"
            >
              {{ item.id }}
            </div>
          </transition-group>
        </draggable>
      </div>
      <div class="col2">
        <div class="colTitle">
          <div>位置</div>
          <div>物料</div>
          <div>备注</div>
        </div>
      </div>
      <div class="col">
        <div class="title">帮面</div>
        <draggable
          v-model="partArr.part1"
          group="site"
          animation="100"
          dragClass="dragClass"
          ghostClass="ghostClass"
          chosenClass="chosenClass"
          @start="onStart"
          @end="onEnd"
          @move="onMove"
        >
          <transition-group>
            <div
              class="item"
              :class="item.active ? 'partSelected' : ''"
              v-for="item in partArr.part1"
              :key="item.id"
              @click="partClick(item, 1)"
            >
              <div>{{ item.id }}</div>
              <div>{{ item.sn }}</div>
              <div>
                <input v-model="item.label" class="labelInput" />
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
      <div class="col">
        <div class="title">车线</div>
        <draggable
          v-model="partArr.part2"
          group="site"
          animation="100"
          dragClass="dragClass"
          ghostClass="ghostClass"
          chosenClass="chosenClass"
          @start="onStart"
          @end="onEnd"
        >
          <transition-group>
            <div
              class="item"
              :class="item.active ? 'partSelected' : ''"
              v-for="item in partArr.part2"
              :key="item.id"
              @click="partClick(item, 2)"
            >
              <div>{{ item.id }}</div>
              <div>{{ item.sn }}</div>
              <div>
                <input v-model="item.label" class="labelInput" />
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
      <div class="col">
        <div class="title">内里</div>
        <draggable
          v-model="partArr.part3"
          group="site"
          animation="100"
          dragClass="dragClass"
          ghostClass="ghostClass"
          chosenClass="chosenClass"
          @start="onStart"
          @end="onEnd"
        >
          <transition-group>
            <div
              class="item"
              :class="item.active ? 'partSelected' : ''"
              v-for="item in partArr.part3"
              :key="item.id"
              @click="partClick(item, 3)"
            >
              <div>{{ item.id }}</div>
              <div>{{ item.sn }}</div>
              <div>
                <input v-model="item.label" class="labelInput" />
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
      <div class="col">
        <div class="title">中底</div>
        <draggable
          v-model="partArr.part4"
          group="site"
          animation="100"
          dragClass="dragClass"
          ghostClass="ghostClass"
          chosenClass="chosenClass"
          @start="onStart"
          @end="onEnd"
        >
          <transition-group>
            <div
              class="item"
              :class="item.active ? 'partSelected' : ''"
              v-for="item in partArr.part4"
              :key="item.id"
              @click="partClick(item, 4)"
            >
              <div>{{ item.id }}</div>
              <div>{{ item.sn }}</div>
              <div>
                <input v-model="item.label" class="labelInput" />
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
      <div class="col">
        <div class="title">大底</div>
        <draggable
          v-model="partArr.part5"
          group="site"
          animation="100"
          dragClass="dragClass"
          ghostClass="ghostClass"
          chosenClass="chosenClass"
          @start="onStart"
          @end="onEnd"
        >
          <transition-group>
            <div
              class="item"
              :class="item.active ? 'partSelected' : ''"
              v-for="item in partArr.part5"
              :key="item.id"
              @click="partClick(item, 5)"
            >
              <div>{{ item.id }}</div>
              <div>{{ item.sn }}</div>
              <div>
                <input v-model="item.label" class="labelInput" />
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
      <div class="col">
        <div class="title">装饰</div>
        <draggable
          v-model="partArr.part6"
          group="site"
          animation="100"
          dragClass="dragClass"
          ghostClass="ghostClass"
          chosenClass="chosenClass"
          @start="onStart"
          @end="onEnd"
        >
          <transition-group>
            <div
              class="item"
              :class="item.active ? 'partSelected' : ''"
              v-for="item in partArr.part6"
              :key="item.id"
              @click="partClick(item, 6)"
            >
              <div>{{ item.id }}</div>
              <div>{{ item.sn }}</div>
              <div>
                <input v-model="item.label" class="labelInput" />
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
      <div class="col">
        <div class="title">内在</div>
        <draggable
          v-model="partArr.part7"
          group="site"
          animation="100"
          dragClass="dragClass"
          ghostClass="ghostClass"
          chosenClass="chosenClass"
          @start="onStart"
          @end="onEnd"
        >
          <transition-group>
            <div
              class="item"
              :class="item.active ? 'partSelected' : ''"
              v-for="item in partArr.part7"
              :key="item.id"
              @click="partClick(item, 7)"
            >
              <div>{{ item.id }}</div>
              <div>{{ item.sn }}</div>
              <div>
                <input v-model="item.label" class="labelInput" />
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import draggable from "vuedraggable";

const { mapState, mapMutations, mapGetters, mapActions } =
  createNamespacedHelpers("jiyang/material");
export default {
  name: "PartSet",
  components: { draggable },
  data() {
    return {
      // partList: [],
      data: [],
      value: 1,
      drag: false,
      arr1: [],
      partArr: {
        part1: [],
        part2: [],
        part3: [],
        part4: [],
        part5: [],
        part6: [],
        part7: [],
      },
    };
  },
  computed: {
    ...mapState(["setPart", "partList"]),
  },
  mounted() {},
  created() {
    // this.$api.getPart({}).then((res) => {
    //   this.partList = res;
    // });
    // let sign = this.global.ModelScene.sign;
    // let _this = this;
    // Object.keys(sign).forEach(function (key) {
    //   _this.data.push({
    //     name: sign[key].text,
    //   });
    // });
    this.partArr = this.global.ModelScene.partArr;
  },
  watch: {
    // setPart(){
    //  this.value = this.setPart;
    // }
    partList() {
      this.arr1 = this.partList;
    },
    // partArr(){
    // 	this.partArr = this.global.ModelScene.partArr;
    // }
  },
  updated() {
    let partArr = this.global.ModelScene.partArr;
    if (partArr) this.partArr = partArr;
    let numArr = [];
    for (let i in partArr) {
      partArr[i].forEach(function (item) {
        numArr.push(item.id);
      });
    }
    for (let j in this.arr1) {
      if (numArr.indexOf(this.arr1[j].id) > -1) {
        this.arr1.splice(j, 1);
      }
    }
  },
  methods: {
    ...mapMutations(["SET_PART_TYPE"]),
    ...mapActions(["getMaterialList"]),
    handleSelectChange(val) {
      let b = this.global.ModelScene;
      b.sign[b.pickedMesh.name].partNum = val;
    },
    deleteNode() {
      let b = this.global.ModelScene;
      b.sign[b.pickedMesh.name].line.dispose();
      b.sign[b.pickedMesh.name].mesh.dispose();
      b.sign[b.pickedMesh.name] = {
        text: b.sign[b.pickedMesh.name].text,
      };
    },
    onStart() {
      this.drag = true;
    },
    onEnd() {
      this.global.ModelScene.partArr = this.partArr;
      this.drag = false;
    },
    //move回调方法
    onMove(e) {
      //不允许停靠
      if (e.relatedContext.element.id == 0) return false;
      return true;
    },
    partClick(item, index) {
      let b = this.global.ModelScene;
      b.selectPart(item.name);
      b.setPosition(item.position);
      this.SET_PART_TYPE([index]);
      this.getMaterialList();
    },
  },
};
</script>

<style lang="scss">
.partSet {
  padding: 10px;

  /*定义要拖拽元素的样式*/
  .ghostClass {
    background-color: blue !important;
  }

  .chosenClass {
    background-color: red !important;
    opacity: 1 !important;
  }

  .dragClass {
    background-color: blueviolet !important;
    opacity: 1 !important;
    box-shadow: none !important;
    outline: none !important;
    background-image: none !important;
  }

  .itxst {
    margin: 10px;
    line-height: 100%;
  }

  .title {
    width: 10% !important;
    padding: 0px 8px;
    font-weight: bolder;
    display: inline-block;
  }

  .col,
  .col2 {
    width: 100%;
    flex: 1;
    // padding: 10px;
    border: solid 1px #999;
    // border-radius: 5px;
    // float: left;
    margin-left: 10px;
  }

  .col + .col {
    margin-left: 10px;
  }
  .col > div {
    display: inline-block;
    width: 90%;
    vertical-align: middle;
  }
  .item {
    // padding: 6px 12px;
    // margin: 0px 5px 0px 5px;
    display: flex;
    // border: solid 1px #eee;
    // background-color: #f1f1f1;
  }

  .item > div {
    flex: 3;
    border-left: 1px solid #999;
    border-bottom: 1px solid #999;
    height: 30px;
    line-height: 30px;
  }

  .item:hover {
    background-color: #fdfdfd;
    cursor: move;
  }

  .item + .item {
    border-top: none;
    // margin-top: 6px;
  }

  .item2 {
    // padding: 6px 12px;
    margin: 0px 5px 0px 5px;
    border: solid 1px #eee;
    background-color: #f1f1f1;
    display: inline-block;
  }

  .item2:hover {
    background-color: #fdfdfd;
    cursor: move;
  }

  .item2 + .item2 {
    border-top: none;
    margin-top: 6px;
  }

  .colTitle {
    width: 90%;
    display: flex;
    margin-left: 10%;
    font-weight: bolder;
  }

  .colTitle > div {
    flex: 3;
    border-right: 1px solid #999;
  }
  .colTitle > div:nth-child(3) {
    border-right: none;
  }

  .partSelected {
    color: #2fc2a9;
  }

  .col span {
    width: 100%;
    min-height: 30px;
    display: block;
  }

  .labelInput {
    width: 100%;
    height: 100%;
  }
}
</style>
