<template>
  <!-- <div class="workEdit" @mousedown="onMousedown($event)" :style="{ top: position.y + 'px', left: position.x + 'px', width: width + 'px', height: height + 'px'}">-->
   <div class="workEdit">
	  <a-tabs default-active-key="1">
		<!-- <a-tab-pane key="1" tab="部件">
	      <a-spin tip="Loading..." :spinning="loading">
			  <part-set></part-set>
	      </a-spin>
	    </a-tab-pane> -->
	    <a-tab-pane key="2" tab="控件">
	      <a-spin tip="Loading..." :spinning="loading">
			<texture-set
			  :visiable.sync="showTools"
			  :type="textureSetType"
			></texture-set>
	      </a-spin>
	    </a-tab-pane>
	    <a-tab-pane key="3" tab="潘通">
	      <a-spin tip="Loading..." :spinning="loading">
			 <color-card></color-card>
	      </a-spin>
	    </a-tab-pane>
	  </a-tabs>
  </div>
</template>

<script>
import MaterialList from "@/components/material/MaterialList.vue";
import LogoList from "@/components/logo-list/LogoList.vue";
import TextureList from "@/components/texture/TextureList.vue";
import TextureSet from "@/components/TextureSet.vue";
import ColorPicker from "@/components/v2/color-picker/ColorPicker.vue";
import { createNamespacedHelpers } from "vuex";
import PartSet from "@/components/PartSet.vue";
import ColorCard from "@/components/ColorCard.vue";

const { mapState, mapMutations, mapGetters, mapActions } =
  createNamespacedHelpers("jiyang/material");
export default {
  name: "assets",
  components: {
    MaterialList,
    LogoList,
    TextureList,
    TextureSet,
    ColorPicker,
    PartSet,
	ColorCard,
  },
  data() {
    return {
      showTools: true,
	  position: {
	  	x: 0,
	  	y: 0
	  },
	  width: 200,
	  height: 400,
    };
  },
  created() {},
  computed: {
    ...mapState(["loading", "textureSetType"]),
  },
  methods: {
    ...mapActions(["textureSet"]),
    tabChange() {},
	onMousedown(e) {
		let w = e.clientX - this.position.x;
		let h = e.clientY - this.position.y;
		if (!this.maximized) {
			this.drag = true;
			document.onmousemove = e => {
				if (this.drag) {
					//let mx = document.body.clientWidth;
					//let my = document.body.clientHeight * 0.95;
					this.position.x = e.clientX - w;
					this.position.y = e.clientY - h;
					//this.position.x = this.position.x <= 0 ? 0 : this.position.x + this.width >= mx ? mx - this.width : this.position.x;
					//this.position.y = this.position.y <= 0 ? 0 : this.position.y + this.height >= my ? my - this.height : this.position.y;
				}
			};
			document.onmouseup = () => {
				//console.log("e===>" + this.drag)
				this.drag = false;
			};
		}
	}
  },
};
</script>

<style lang="scss">
.workEdit {
  height: 100%;
  min-width: 340px;
  max-width: 340px;
  width: 20%;
  background-color: #fff;
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
  // position: absolute;
  .tab-content {
    width: 100%;
    height: auto !important;
    padding: 0 12px;
  }
  .tab-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;
    .ant-input-group {
      margin-right: 16px;
    }
    .ant-btn-icon-only {
      width: 48px;
    }
    .ant-btn {
      margin-right: 8px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  .pcr-app {
    box-shadow: none;
    width: 24em !important;
  }
}
</style>
