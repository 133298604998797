<template>
  <div>
    <div style="padding: 10px">
      <a-input
        v-model:value="value"
        placeholder="请输入潘通色卡号"
        @change="forColor"
      />
    </div>
    <div class="pantone">
      <div
        v-for="(item, index) in pantone"
        :style="{ backgroundColor: item }"
        class="colorBox"
        @click="selectColor(item)"
      >
        <a-tooltip placement="bottom" :title="index">
          <div style="width: 100%; height: 100%"></div>
        </a-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import pantone from "../utils/pantone.js";

export default {
  data() {
    return {
      pantone: {},
      value: "",
    };
  },
  created() {
    this.pantone = pantone;
  },
  methods: {
    forColor() {
      if (this.value) {
        let pantoneArr = {};
        for (let item in pantone) {
          if (item.indexOf(this.value) != -1) {
            pantoneArr[item] = pantone[item];
          }
        }
        this.pantone = pantoneArr;
      } else {
        this.pantone = pantone;
      }
    },
    selectColor(value) {
      if (this.global.ModelScene.pickedMesh) {
        let color = this.hex2rgb(value);
        let color2 = new BABYLON.Color3(
          color[0] / 255,
          color[1] / 255,
          color[2] / 255
        );
        this.global.ModelScene.pickedMesh.material.albedoColor = color2;
        this.global.ModelScene.pickedMesh.material.reflectionColor = color2;
        this.global.ModelScene.pickedMesh.material.directIntensity =
          (color[0] / 255) * 0.3 +
          (color[1] / 255) * 0.6 +
          (color[2] / 255) * 0.1;
      }
    },
    hex2rgb(
      a //将16进制颜色转换成rgb
    ) {
      a = a.substring(1);
      a = a.toLowerCase();
      let b = new Array();
      for (let x = 0; x < 3; x++) {
        b[0] = a.substr(x * 2, 2);
        b[3] = "0123456789abcdef";
        b[1] = b[0].substr(0, 1);
        b[2] = b[0].substr(1, 1);
        b[20 + x] = b[3].indexOf(b[1]) * 16 + b[3].indexOf(b[2]);
      }
      return [b[20], b[21], b[22]];
    },
  },
};
</script>

<style lang="scss">
.pantone {
  height: 500px;
  overflow-y: scroll;
  .colorBox {
    width: 30px;
    height: 30px;
    display: inline-block;
  }
  .colorBox:hover {
    border: 1px solid #999;
  }
}
</style>
