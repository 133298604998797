<template>
  <div class="work">
    <div class="prod">
      <assets></assets>
      <work-space></work-space>
      <work-edit></work-edit>
    </div>
  </div>
</template>

<script>
import assets from "@/components/Assets";
import WorkSpace from "@/components/WorkSpace";
import WorkEdit from "@/components/WorkEdit";

export default {
  components: {
    assets,
    WorkSpace,
    WorkEdit,
  },
  created() {
    let query = this.$route.query;
    this.token = query.token;
    localStorage.setItem("token", this.token);
  },
};
</script>

<style lang="scss">
.work {
  position: absolute;
  height: 100%;
  width: 100%;
  .prod {
    height: 100%;
    display: flex;
    overflow: hidden;
  }
}
</style>
